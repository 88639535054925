import { useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  LadderSetting,
  useGetLadderSettingQuery,
  useUpdateLadderSettingMutation,
} from 'manage-tritag/services/api/endpoints/ladder-settings'
import { sortLadderOrder } from 'manage-tritag/utils/string'
import Spinner from 'manage-tritag/components/loading/spinner'
import { useMediaQuery } from 'react-responsive'
import { useAuth0 } from '@auth0/auth0-react'
import {
  FormContent,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledSelect,
  StyledTextField,
} from './styles'
import LadderSettingsSort from './ladder-sort'

const LadderSettingsForm = () => {
  const LADDER_SETTINGS_ID =
    process.env.LADDER_SETTINGS_ID ?? '614d0b3a10ec2d0750e49d30'
  const isMobile = useMediaQuery({ query: `(max-width: 898px)` })
  const { user } = useAuth0()
  const [updateLadder, { isLoading: updateLoading }] =
    useUpdateLadderSettingMutation()
  const { data: ladderData, isLoading: dataLoading } =
    useGetLadderSettingQuery(LADDER_SETTINGS_ID)
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      winPoints: 0,
      lossPoints: 0,
      drawPoints: 0,
      byePoints: 0,
      byeAsPlayed: 0,
      forfeitPointsFor: 0,
      forfeitPointsAgainst: 0,
      forfeitAsPlayed: 0,
    },
  })

  const [isLoading, setIsLoading] = useState(false)
  const [authLoading, setAuthLoading] = useState(true)

  useEffect(() => {
    if (user) {
      if (user['https://tritagrugby.com/user_metadata']) {
        if (user['https://tritagrugby.com/user_metadata'].activeRole) {
          if (
            ![1].includes(
              user['https://tritagrugby.com/user_metadata'].activeRole.type,
            )
          ) {
            navigate('/')
          } else {
            setAuthLoading(false)
          }
        }
      }
    }
  }, [user])

  const [items, setItems] = useState([
    'points',
    'pointsDifference',
    'pointsFor',
    'winRatio',
  ])

  useEffect(() => {
    setIsLoading(true)
    if (ladderData) {
      reset({
        winPoints: ladderData.winPoints,
        lossPoints: ladderData.lossPoints,
        drawPoints: ladderData.drawPoints,
        byePoints: ladderData.byePoints,
        byeAsPlayed: ladderData.byeAsPlayed,
        forfeitPointsFor: ladderData.forfeitPointsFor,
        forfeitPointsAgainst: ladderData.forfeitPointsAgainst,
        forfeitAsPlayed: ladderData.forfeitAsPlayed,
      })

      setItems(sortLadderOrder(ladderData.sortOrder))
    }
    setIsLoading(false)
  }, [reset, ladderData])

  const onSubmit: SubmitHandler<LadderSetting> = async (updateData: any) => {
    const formData = {
      ...updateData,
      sortOrder: {
        points: {
          order: items.indexOf('points') + 1,
        },
        pointsDifference: {
          order: items.indexOf('pointsDifference') + 1,
        },
        pointsFor: {
          order: items.indexOf('pointsFor') + 1,
        },
        winRatio: {
          order: items.indexOf('winRatio') + 1,
        },
      },
    }
    await updateLadder({ _id: LADDER_SETTINGS_ID, ...formData }).unwrap()
    reset()
    toast.success('Ladder settings updated succesfully.')
    navigate('/ladder-settings')
  }

  if (dataLoading || isLoading || authLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 145px);',
        }}
      >
        <Spinner size={40} color="#008174" />
      </Box>
    )

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <StyledGridContainer container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={12}>
          <Typography sx={{ fontWeight: 'bold', color: '#008174' }}>
            Ladder settings
          </Typography>
          <FormContent>
            <Controller
              name="winPoints"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 1 }}
                  type="number"
                  id="filled-basic"
                  label="Win points"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="lossPoints"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 2 }}
                  type="number"
                  id="filled-basic"
                  label="Loss points"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="drawPoints"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 3 }}
                  type="number"
                  id="filled-basic"
                  label="Draw points"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />
          </FormContent>

          <FormContent>
            <Controller
              name="byePoints"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 4 }}
                  type="number"
                  id="filled-basic"
                  label="Bye points"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="forfeitPointsFor"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 5 }}
                  type="number"
                  id="filled-basic"
                  label="Forfeit points for"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="forfeitPointsAgainst"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 6 }}
                  type="number"
                  id="filled-basic"
                  label="Forfeit points against"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />
          </FormContent>

          <FormContent>
            <FormControl variant="filled" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Count bye as played
              </InputLabel>
              <Controller
                name="byeAsPlayed"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 7 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    size="small"
                    {...field}
                  >
                    <MenuItem value={1}>
                      <em>Yes</em>
                    </MenuItem>
                    <MenuItem value={0}>
                      <em>No</em>
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl variant="filled" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Count forfeit as played
              </InputLabel>
              <Controller
                name="forfeitAsPlayed"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    inputProps={{ tabIndex: 8 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    size="small"
                    {...field}
                  >
                    <MenuItem value={1}>
                      <em>Yes</em>
                    </MenuItem>
                    <MenuItem value={0}>
                      <em>No</em>
                    </MenuItem>
                  </StyledSelect>
                )}
              />
            </FormControl>

            {!isMobile && <Box sx={{ width: '100%' }} />}
          </FormContent>
        </Grid>
      </StyledGridContainer>
      <LadderSettingsSort items={items} setItems={setItems} />
      <StyledButtonDiv>
        <div />
        <div>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '83px' }}
          >
            {updateLoading ? <Spinner size={22} /> : 'SAVE'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
    </Box>
  )
}

export default LadderSettingsForm
