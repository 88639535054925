import { api } from '../index'

export type LadderSetting = any
export type LadderSettingsResponse = LadderSetting[]

export const ladderSettingsApi = api.injectEndpoints({
  endpoints: build => ({
    getLadderSettings: build.query<LadderSettingsResponse, void>({
      query: () => 'ladder-settings',
      providesTags: ['ladder-settings'],
    }),
    getLadderSetting: build.query<LadderSetting, string>({
      query: id => `ladder-settings/${id}`,
      providesTags: ['ladder-setting'],
    }),
    addLadderSetting: build.mutation({
      query: body => ({
        url: 'ladder-settings',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ladder-settings'],
    }),
    updateLadderSetting: build.mutation({
      query: body => ({
        url: `ladder-settings/${body._id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ladder-settings', 'ladder-setting'],
    }),
    deleteLadderSetting: build.mutation({
      query: id => ({
        url: `ladder-settings/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ladder-setting', 'ladder-settings'],
    }),
  }),
})

export const {
  useGetLadderSettingsQuery,
  useGetLadderSettingQuery,
  useAddLadderSettingMutation,
  useUpdateLadderSettingMutation,
  useDeleteLadderSettingMutation,
} = ladderSettingsApi
