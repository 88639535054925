import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import LadderSettingsForm from 'manage-tritag/components/pages/ladders/form'

const LadderPage = () => {
  return (
    <Layout label1="Ladder Settings" url="/ladder-settings">
      <LadderSettingsForm />
    </Layout>
  )
}

export default withAuthenticationRequired(LadderPage)
