import { FC } from 'react'
import { Box, Grid, styled, Typography } from '@mui/material'
import { arrayMoveImmutable } from 'array-move'
import { convertCamelToStr } from 'manage-tritag/utils/string'
import SortableList, { SortableItem } from 'react-easy-sort'
import { StyledItem } from './styles'

interface LadderSettingsSortProps {
  items: string[]
  setItems: (param: any) => void
}

const LadderSettingsSort: FC<LadderSettingsSortProps> = ({
  items,
  setItems,
}) => {
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setItems((array: any) => arrayMoveImmutable(array, oldIndex, newIndex))
  }

  const StyledGrid = styled(Grid)`
    ${({ theme }) => {
      return {
        [theme.breakpoints.up(900)]: {
          paddingLeft: '0% !important',
        },
      }
    }}
  `
  const StyledGridContainer = styled(Grid)`
    ${({ theme }) => {
      return {
        [theme.breakpoints.down(900)]: {
          marginBottom: '0rem !important',
        },
      }
    }}
    margin-bottom: 2rem !important;
  `
  return (
    <Box component="div">
      <StyledGridContainer container spacing={3} justifyContent="space-between">
        <StyledGrid item xs={12} sm={12} md={4}>
          <Typography
            sx={{ fontWeight: 'bold', color: '#008174', marginBottom: '1rem' }}
          >
            Ladder sorting
          </Typography>
          <SortableList
            onSortEnd={onSortEnd}
            style={{ userSelect: 'none' }}
            draggedItemClassName="dragged"
          >
            {items.map((item, i) => (
              <SortableItem key={item}>
                <StyledItem>
                  <Grid container>
                    <Grid item xs={1} sm={1} md={2}>
                      {i + 1}
                    </Grid>
                    <Grid item xs={11} sm={11} md={10}>
                      {convertCamelToStr(item)}
                    </Grid>
                  </Grid>
                </StyledItem>
              </SortableItem>
            ))}
          </SortableList>
        </StyledGrid>
        <Grid item xs={12} sm={12} md={4} />
        <Grid item xs={12} sm={12} md={4} />
      </StyledGridContainer>
    </Box>
  )
}

export default LadderSettingsSort
