import { Button, Grid, Select, styled, TextField, Box } from '@mui/material'

export const StyledGridContainer = styled(Grid)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        marginBottom: '2rem !important',
      },
    }
  }}
  margin-bottom: 2rem !important
`

export const SecondGrid = styled(Grid)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '0px !important',
      },
    }
  }}
`
export const SpaceDivider = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        display: 'none !important',
      },
    }
  }}
  opacity: 0;
`

export const FormContent = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        flexDirection: 'column',
        rowGap: '2.5rem',
        marginTop: '2.4rem',
        marginBottom: '0',
      },
    }
  }}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1.3rem;
  margin-top: 2rem;
`
export const SecondFormContent = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        marginTop: '1rem',
      },
    }
  }}
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
`

export const StyledButtonDiv = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  font-size: 0.8rem;
`

export const StyledTextField = styled(TextField)`
  width: 100%;
`
export const StyledSelect = styled(Select)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.up(900)]: {
        width: '90%',
      },
    }
  }}
`

export const StyledItem = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.up(900)]: {
        width: '90%',
      },
    }
  }}
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  color: #000;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin: 0px 0px 8px 0px;
  padding: 0.5rem;
  height: 40px;
  border-radius: 0.4rem;
  cursor: grab;
`
export const StyledBox = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        paddingLeft: '0',
      },
    }
  }}
  padding-left: 1.5rem;
`
